<template>
  <div
    class="HomeView">
    <HeaderStep
      :logo="{ ...(flow?.headerLogo), color: '#FFFFFF' }"
      :theme="currentStep.headerTheme"
      :title="$t('flows.student.steps.home.title')"
      :subtitle="$t('flows.student.steps.home.subtitle')"
    />

    <div class="container" style="padding-bottom: 80px;">
      <form
        class="Field"
        id="HomeView"
        @submit.prevent="handleSubmit">
        <InputText
          class="Field__input"
          ref="input"
          v-model.trim="session.email.value"
          type="email"
          :placeholder="session.email.placeholder"
          :is-placeholder-persistent="true"
          :is-disabled="isSubmittingEmail"
          :is-invalid="isEmailError"
          :input-style="{ border: 'none !important' }"
        />

        <ButtonBase
          class="Field__button uppercase"
          type="submit"
          :is-loading="isSubmittingEmail">
          {{ $t('ctas.signUp') }}
        </ButtonBase>
      </form>

      <ErrorMessage
        class="mt-1"
        v-show="isEmailError || isEmailRateLimitError">
        <span v-html="computedErrorMessage"></span>
      </ErrorMessage>

      <div class="ConsentBox">
        <InputCheckbox
            v-model="session.eligible.value"
            :is-invalid="hasSubmitted && !session.eligible.isValidClientSide">
            {{ $t('flows.student.steps.home.eligibleLabel') }}
          </InputCheckbox>

          <ErrorMessage
            class="mt-1 ml-3"
            v-show="hasSubmitted && !session.eligible.isValidClientSide">
            {{ $t('flows.student.steps.home.eligibleError') }}
          </ErrorMessage>
      </div>

      <div
        class="__SignIn">
        {{ $t('ctas.alreadyDomoUser') }}
        <a
          class="__legal-link font-semi-bold ml-half"
          href="https://www.domo.com/login">
          {{ $t('ctas.signIn') }}
        </a>
      </div>

      <LogoBanner
        class="__LogoBanner"
        :label="$t('shared.trustedBy')"
        :breakpoints="[
          { width: 0, columns: 3, rows: 1 },
          { width: 550, columns: 4, rows: 1 },
        ]"
        :logos="[
        {
          name: 'Taylormade',
          maxWidth: 120,
          src: 'https://web-assets.domo.com/miyagi/images/customer/taylormade/logo/logo-customer-taylormade-black.svg',
        },
        {
          name: 'Univision',
          maxWidth: 60,
          src: 'https://web-assets.domo.com/miyagi/images/customer/univision/logo/logo-customer-univision-black.svg',
        },
        {
          name: 'BBVA',
          maxWidth: 80,
          src: 'https://web-assets.domo.com/miyagi/images/customer/bbva/logo/logo-customer-bbva-black.svg',
        },
        {
          name: 'DHL',
          src: 'https://web-assets.domo.com/miyagi/images/customer/dhl/logo/logo-customer-dhl-black.svg',
        },
      ]"
      />

      <ListIcons
        v-if="computedFeatures.list"
        class="mt-4"
        :items="computedFeatures.list"
        :label="computedFeatures.label"
        key="home-features">
      </ListIcons>
    </div>
  </div>
</template>


<script>
import useEmail from '@/use/email';
import ButtonBase from '@/components/ButtonBase.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import HeaderStep from '@/components/HeaderStep.vue';
import InputCheckbox from '@/components/InputCheckbox.vue';
import InputText from '@/components/InputText.vue';
import ListIcons from '@/components/ListIcons.vue';
import LogoBanner from '@/components/LogoBanner.vue';

export default {
  name: 'HomeView',

  components: {
    ButtonBase,
    ErrorMessage,
    HeaderStep,
    InputCheckbox,
    InputText,
    ListIcons,
    LogoBanner,
  },

  inject: [
    'flow',
    'session',
    'currentStep',
    'trackEvent',
  ],

  setup() {
    const {
      isEmailError,
      isEmailRateLimitError,
      isSubmittingEmail,
      submitEmail,
    } = useEmail();

    return {
      isEmailError,
      isEmailRateLimitError,
      isSubmittingEmail,
      submitEmail,
    };
  },

  data() {
    return {
      isSubmitting: false,
      hasSubmitted: false,
    };
  },

  computed: {
    computedFeatures() {
      const stepMessages = this.$i18n.messages[this.$i18n.locale].flows.student?.steps?.home;

      return {
        label: stepMessages?.featuresLabel,
        list: stepMessages?.features,
      };
    },

    computedErrorMessage() {
      if (this.isEmailRateLimitError) return this.$t('shared.rateLimitError');
      return this.$t('flows.student.steps.home.studentEmailError');
    },
  },

  mounted() {
    this.focusEmailInput();
  },

  methods: {
    handleSubmit() {
      this.hasSubmitted = true;

      if (!this.session.eligible.isValidClientSide) return;

      this.submitEmail({
        flowName: this.$route.params.flowName,
        session: this.session,
        onSuccess: this.handleSuccess,
        onError: this.handleError,
      });
    },

    handleSuccess() {
      this.trackEvent('submitted email address', this.currentStep, this.session);

      this.$router.push({
        name: 'step',
        params: {
          lang: this.$route.params.lang || '',
          flowName: this.$route.params.flowName,
          stepName: 'confirm',
        },
        query: this.$route.query,
      });
    },

    handleError() {
      this.focusEmailInput();
    },

    focusEmailInput() {
      setTimeout(() => this.$refs.input.select(), 100);
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.Field {
  display: flex;
  padding: 4px;
  align-items: stretch;
  border-radius: $radius;
  background-color: $white;
  box-shadow: 0 8px 48px -8px rgba($black, 0.5);
  .Field__input {
    flex: 1 1 auto;
  }
  .Field__button {
    flex: 0 0 auto;
  }
}

.ConsentBox {
  border: $border;
  border-radius: $radius;
  margin-top: 24px;
  padding: 20px;
}

.__SignIn {
  color: #111111;
  font-size: 14px;
  padding-right: 8px;
  padding-top: 16px;
  text-align: right;
  white-space: nowrap;
}

.__LogoBanner {
  margin-top: 40px;
  opacity: 0.46;
}

@media only screen and (max-width: 430px) {
  .Field {
    display: block;
    padding: 0px;
    background-color: transparent;
    box-shadow: none;
    .Field__input {
      border-radius: $radius;
      background-color: $white;
      box-shadow: 0 16px 56px -8px rgba($black, 0.5);
      flex: none;
      height: 48px;
    }
    .Field__button {
      display: block;
      flex: none;
      margin-top: 8px;
      width: 100%;
    }
  }
  .__SignIn {
    text-align: center;
  }
}
</style>
